import { Typography } from '@mui/material';

function Footer() {
  return (
    <Typography
      variant="subtitle2"
      component="div"
      p={2}
      textAlign="center"
      sx={{ backgroundColor: (theme) => theme.palette.common.white }}
      mt={2}
    >
      © 2024 All rights reserved by VGSSHOP.COM
    </Typography>
  );
}

export default Footer;
